<template>
  <div class="qingwu">
    <div class="admin_main_block">
      <div class="admin_breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/Admin/index' }"
            >首页</el-breadcrumb-item
          >
          <el-breadcrumb-item>联盟商家</el-breadcrumb-item>
          <el-breadcrumb-item>门店类目</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="admin_main_block admin_m15">
      <div class="header-form">
        <div class="admin_main_block_right">
          <el-row :gutter="20">
            <el-col :span="24">
              <el-button
                type="primary"
                @click="setDialog({ hierarchy: 1, parentId: 0 }, 'first')"
                icon="el-icon-plus"
                >新增一级分类
              </el-button>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="admin_table_main">
        <el-table
          :data="list"
          @selection-change="handleSelectionChange"
          row-key="id"
          lazy
          :load="load"
          :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
        >
          <el-table-column width="80px"></el-table-column>
          <!-- <el-table-column prop="id" label="#" fixed="left" width="70px"></el-table-column> -->
          <el-table-column label="栏目名称">
            <template slot-scope="scope">
              <dl class="table_dl">
                <dt v-if="scope.row.categoryIcon">
                  <el-image
                    style="width: 50px; height: 50px"
                    :src="getIconUrl(scope.row)"
                  >
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
                </dt>
                <dd class="table_dl_dd_all">{{ scope.row.categoryName }}</dd>
              </dl>
            </template>
          </el-table-column>
          <el-table-column prop="ordering" label="排序"></el-table-column>
          <el-table-column label="操作" fixed="right">
            <template slot-scope="scope">
              <el-button
                type="success"
                icon="el-icon-add"
                v-if="scope.row.hierarchy < 3"
                @click="
                  setDialog(
                    {
                      hierarchy: scope.row.hierarchy + 1,
                      parentId: scope.row.id,
                    },
                    'add'
                  )
                "
              >
                添加子分类
              </el-button>
              <el-button
                type="primary"
                icon="el-icon-edit"
                @click="setDialog(scope.row, 'edit')"
                >编辑
              </el-button>
              <el-button
                type="danger"
                icon="el-icon-delete"
                @click="del(scope.row.id)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>

        <div class="admin_table_main_pagination">
          <el-pagination
            @current-change="currentChange"
            @size-change="handleSizeChange"
            background
            :total="totalData"
            :page-size="pageSize"
            :current-page="currentPage"
            :page-sizes="[10, 20, 50, 100]"
            layout="sizes, prev, pager, next, total, jumper"
          ></el-pagination>
        </div>
        <add-type
          :row="infoDetail"
          @dialog="getDialog"
          v-if="dialogAddVisible"
        ></add-type>
      </div>
    </div>
  </div>
</template>
<script>
import { staticUrl } from "@/plugins/api";
import addType from "@/components/admin/merchant/category/add.vue";

export default {
  components: {
    addType,
  },
  data() {
    return {
      list: [],
      selectId: "",
      dialogAddVisible: false,
      dialogChildrenVisible: false,
      infoDetail: {},
      totalData: 0, // 总条数
      pageSize: 20,
      currentPage: 0,
      goods_verify_count: 0,
      where: {
        goods_name: "",
        limit: 0,
        page: 1,
        parentId: 0,
      },
    };
  },
  methods: {
    handleSelectionChange(e) {
      let ids = [];
      e.forEach((v) => {
        ids.push(v.id);
      });
      this.selectId = ids.join(",");
    },
    getCategoryList() {
      this.where.page = this.currentPage;
      this.where.limit = this.pageSize;
      this.$post(this.$api.merchantCategoryList, this.where).then((res) => {
        this.totalData = res.data.count;
        this.list = res.data.records;
        this.list.forEach((rs, index) => {
          this.list[index].hasChildren = true;
        });
      });
    },
    // 删除处理
    del(id) {
      if (this.$isEmpty(id)) {
        return this.$message.error("请先选择删除的对象");
      }
      this.$confirm("是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$postFormData(this.$api.merchantCategoryDel, { id }).then(
            (res) => {
              if (res.code == 1) {
                this.getCategoryList();
                this.$router.go(0);
                this.$message.success("删除成功");
              } else {
                this.$message.error("删除失败");
              }
            }
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    getIconUrl(row) {
      return staticUrl + row.categoryIcon;
    },
    currentChange(e) {
      this.currentPage = e;
      this.getCategoryList();
    },
    handleSizeChange(e) {
      this.pageSize = e;
      this.getCategoryList();
    },
    load(tree, treeNode, resolve) {
      this.$postFormData(this.$api.merchantCategoryChildList, {
        parentId: tree.id,
      }).then((res) => {
        if (res.code == 1) {
          let list = [];
          res.data.forEach((rs, index) => {
            if (rs.hierarchy < 3) {
              rs.hasChildren = true;
            }
            list[index] = rs;
          });
          resolve(list);
        }
      });
    },
    getDialog(val) {
      this.dialogAddVisible = val;
      this.dialogChildrenVisible = val;
    },
    setDialog(val, type) {
      this.infoDetail = val;
      this.infoDetail.type = type;
      this.dialogAddVisible = true;
    },
  },
  created() {
    this.getCategoryList();
  },
};
</script>
<style lang="scss" scoped>
// .admin_main_block_right {
//     /*width: 14%;*/
// }

.admin_main_block_right .el-row {
  width: 100%;
}

.admin_main_block_right div {
  float: left;
}

.el-upload-dragger {
  height: 150px;
}
</style>
